<template>
  <center-content>
    <form @submit.prevent="forgetPassword">
      <base-card>
        <template #header>إستعادة كلمة المرور</template>
        <template #body>
          <div v-if="!sent">
            <div class="form-group text-left">
              <label>البريد الإلكتروني</label>
              <form-input
                id="email"
                placeholder="البريد الإلكتروني"
                autofocus
              ></form-input>
            </div>
            <button type="submit" class="btn btn-dark nr-block mb-1">
              تأكيد
            </button>
            <p class="text-left mb-0">
              <span>لديك حساب؟</span>&nbsp;
              <router-link to="/login" class="link danger"
                >تسجيل الدخول</router-link
              >
            </p>
            <p class="text-left mb-0">
              <span>ليس لديك حساب؟</span>&nbsp;
              <router-link to="/register" class="link danger"
                >تسجيل حساب جديد</router-link
              >
            </p>
          </div>
          <div v-else>
            <p @click="redirectToLogin" class="link">{{ sentDesc }}</p>
          </div>
        </template>
      </base-card>
    </form>
  </center-content>
</template>

<script>
import CenterContent from "@/components/UI/CenterContent";
import BaseCard from "@/components/UI/BaseCard";
import FormInput from "@/components/Forms/FormInput";

export default {
  components: { FormInput, BaseCard, CenterContent },
  data() {
    return {
      sent: false,
      sentDesc: "",
    };
  },
  methods: {
    async forgetPassword(e) {
      const formData = new FormData(e.target);
      if (!formData || !formData.get("email"))
        return http.popupMessage("error", "يرجى إدخال بريد إلكتروني صالح");
      const response = await http.send("forget-password", formData);
      if (http.responseAccepted(response)) {
        $_("#email").val("");
        this.sent = true;
        this.sentDesc = response.data.msg;
      }
    },
    redirectToLogin() {
      this.$router.replace("/login");
    },
  },
};
</script>
